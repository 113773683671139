import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { Layout, LayoutWithMainContent } from '../../components/layout/Layout';
import { DEFAULT_IMG_URL, MAIN_DESCRIPTION, MAIN_TITLE } from '../../lib/constants';
import { SectionBlocks } from '../../components/SectionBlocks';
import { useFrontPage } from '../../hooks/useFrontPage';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import { LoaderContent } from '../LoaderPage';
import { useWindowLocation } from '../../hooks/Window/useWindowLocation';
import { frontPagePath } from '../../components/ThemedApp';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';
import { ThemeHeroBlock } from '../../components/SectionBlocks/blocks/ThemeHeroBlock/ThemeHeroBlock';
import { Hero } from './components/Hero/Hero';
import { NotFound } from '../../loadable-elements/NotFound';
import { NAFGTMEvent } from '../../../../types/GTM/NAFGTMEvent';
import { useGTMDataLayer } from '../../hooks/GTM/useGTMDataLayer';

export const FrontPage = ({ abTestWeight }: { abTestWeight?: number }) => {
  const { simpleToken } = useAuth0Token();
  const [tracked, setTracked] = useState(false);
  const datalayer = useGTMDataLayer();

  const {
    meta: { isUpdating },
    error,
    data,
  } = useFrontPage(simpleToken, abTestWeight);
  const windowPath = useWindowLocation();

  const { hero, dynamicHero, content } = data || {};
  const dynamicHeroBlock = useMemo(() => ({ ...dynamicHero, isFrontPage: true }), [dynamicHero]);

  useEffect(() => {
    if (!tracked && data?.abTestOnPageExperimentDetails) {
      datalayer.push({
        event: NAFGTMEvent.abExperimentParticipant,
        experimentId: data.abTestOnPageExperimentDetails.experimentId,
        variantId: data.abTestOnPageExperimentDetails.variantId,
      });
      setTracked(true);
    }
  }, [tracked, data?.abTestOnPageExperimentDetails, datalayer]);

  if (data) {
    return (
      <Layout
        title={MAIN_TITLE}
        description={MAIN_DESCRIPTION}
        url={frontPagePath}
        imgUrl={DEFAULT_IMG_URL}
        internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      >
        {hero && <Hero hero={hero} />}
        {dynamicHero && (
          <FrontPageHeroWrapper>
            <ThemeHeroBlock block={dynamicHeroBlock} />
          </FrontPageHeroWrapper>
        )}
        <SectionContainer>
          <SectionBlocks content={content} />
        </SectionContainer>
      </Layout>
    );
  }

  if (isUpdating === false && error) {
    return <NotFound />;
  }

  if (isUpdating) {
    return (
      <LayoutWithMainContent
        title={MAIN_TITLE}
        description={MAIN_DESCRIPTION}
        url={windowPath}
        imgUrl={DEFAULT_IMG_URL}
        internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      >
        <LoaderContent />
      </LayoutWithMainContent>
    );
  }

  return (
    <LayoutWithMainContent
      title={MAIN_TITLE}
      description={MAIN_DESCRIPTION}
      url={windowPath}
      imgUrl={DEFAULT_IMG_URL}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    />
  );
};

export const SectionContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: ${breakpoints.xl};
  margin: auto;
  padding: ${spacing.space40} ${spacing.space48} ${spacing.space64} ${spacing.space48};
  flex-direction: column;
  row-gap: ${spacing.space120};

  @media (max-width: ${breakpoints.xl}) {
    padding-top: ${spacing.space48};
  }

  @media (max-width: ${breakpoints.l}) {
    padding: ${spacing.space24} ${spacing.space24} ${spacing.space64};
    row-gap: ${spacing.space48};
  }
`;

const FrontPageHeroWrapper = styled.div`
  [class^='AnimateText__HideOverflow'] {
    position: absolute;
    bottom: ${spacing.space48};
    @media (max-width: ${breakpoints.m}) {
      bottom: ${spacing.space64};
    }
  }
`;
